<template>
  <div class="heavyItems">
    <!--列表-->
    <div class="contentArea">
      <div class="right page-container-table">
        <div class="list-operation">
          <!-- 搜索筛选 -->
          <el-form class="search-box" :inline="true" :model="formInline">
            <el-form-item label="">
              <el-cascader
                v-model="cascaderValue"
                placeholder="请选择品名"
                :props="categoryCascadeProps"
                clearable
                @change="categoryChange"
              />
            </el-form-item>
            <el-form-item label="">
              <el-select v-model="formInline.materials" filterable size="small" placeholder="请选择材质" @focus="judgement" @change="$forceUpdate()">
                <el-option label="全部" value />
                <el-option
                  v-for="(item, index) in editNeedFormData.varietyData6"
                  :key="index"
                  :label="item.materials"
                  :value="item.materials"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-select v-model="formInline.specs" filterable size="small" placeholder="请选择规格" @focus="judgement" @change="$forceUpdate()">
                <el-option label="全部" value />
                <el-option
                  v-for="(item, index) in editNeedFormData.varietyData7"
                  :key="index"
                  :label="item.specs"
                  :value="item.specs"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" @click="getdata">
                搜索
              </el-button>
              <el-button size="small" @click="resetFormInline">
                重置
              </el-button>
            </el-form-item>
          </el-form>
          <el-button
            size="small"
            type="primary"
            class="add-button"
            @click="editAndAddRow"
          >
            添加
          </el-button>
        </div>
        <Table
          :item-data="itemData"
          :list-data="listData"
          :operation-button="operationButton"
          :loading="loading"
        />
        <!-- 分页组件 -->
        <Pagination
          :params="formInline"
          :total="total"
          :in-article="listData.length"
          :get-data-list="getdata"
        />
      </div>
    </div>
    <!-- 编辑and新增弹出层 -->
    <el-dialog
      :title="title"
      :visible.sync="editFormVisible"
      :close-on-click-modal="false"
      width="40%"
      @click="editFormVisible = false"
    >
      <el-form
        ref="editForm"
        :model="editFormData"
        class="from"
        label-position="left"
        label-width="120px"
        :rules="rules"
      >
        <el-form-item label="品类(一级)" prop="categoryId">
          <el-select
            v-model="editFormData.categoryId"
            size="small"
            placeholder="请选择"
            @change="getChildNode(1, editFormData.categoryId, 123)"
          >
            <el-option
              v-for="(item, index) in editNeedFormData.varietyData1"
              :key="index"
              :label="item.goodsName"
              :value="item.code"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="品种(二级)" prop="varietyId">
          <el-select
            v-model="editFormData.varietyId"
            size="small"
            placeholder="请先选择品类"
            @change="getChildNode(2, editFormData.varietyId, 123)"
          >
            <el-option
              v-for="(item, index) in editNeedFormData.varietyData2"
              :key="index"
              :label="item.goodsName"
              :value="item.code"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="品名(三级)" prop="goodsId">
          <el-select
            v-model="editFormData.goodsId"
            size="small"
            placeholder="请先选择品种"
            @change="getFollowingData"
          >
            <el-option
              v-for="(item, index) in editNeedFormData.varietyData3"
              :key="index"
              :label="item.goodsName"
              :value="item.code"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="材质" prop="materials">
          <el-select
            v-model="editFormData.materials"
            size="small"
            reserve-keyword
            filterable
            allow-create
            clearable
            default-first-option
            :remote-method="remoteMethod1"
            placeholder="请选择材质"
          >
            <el-option
              v-for="(item, index) in editNeedFormData.varietyData4"
              :key="index"
              :label="item.materials"
              :value="item.materials"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="规格" prop="specs">
          <el-select
            v-model="editFormData.specs"
            size="small"
            reserve-keyword
            filterable
            allow-create
            clearable
            default-first-option
            :remote-method="remoteMethod2"
            placeholder="请选择规格"
          >
            <el-option
              v-for="(item, index) in editNeedFormData.varietyData5"
              :key="index"
              :label="item.specs"
              :value="item.specs"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="产地" prop="productions">
          <el-select
            v-model="editFormData.productions"
            filterable
            size="small"
            placeholder="请输入产地"
            reserve-keyword
            clearable
            remote
            :remote-method="remoteMethod"
            @focus="remoteMethod"
          >
            <el-option
              v-for="item in restaurants"
              :key="item.id"
              :label="item.brandProduction"
              :value="item.brandProduction"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="件重(吨)">
          <el-input-number
            v-model="editFormData.weights"
            size="small"
            :min="0"
            :precision="2"
            :controls="false"
            auto-complete="off"
            placeholder="请输入件重"
            type="number"
          />
        </el-form-item>
        <el-form-item label="长度(米)">
          <el-input
            v-model="editFormData.length"
            size="small"
            auto-complete="off"
            placeholder="请输入长度"
            type="number"
          />
        </el-form-item>
        <el-form-item label="描述" prop="remark">
          <el-input
            v-model="editFormData.remark"
            size="small"
            auto-complete="off"
            placeholder="请输入描述"
          />
        </el-form-item>
        <!-- <el-form-item label="状态" prop="state">
          <el-radio-group v-model="editFormData.state">
            <el-radio :label="1">有效</el-radio>
            <el-radio :label="0">无效</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <h3 class="otherAttributes">
          其他属性
          <div class="addButton">
            <span @click="propertyListData.push({detailId:Date.now()})">添加</span>
          </div>
        </h3>
        <Table
          class="propertyList"
          :item-data="propertyItemData"
          :list-data="propertyListData"
          :operation-button="propertyOperationButton"
        >
          <template #propertyName="{ row }">
            <el-input
              v-model="row.propertyName"
              placeholder="请输入属性名"
              @change="changeItem(row, 'propertyName')"
            />
          </template>
          <template #propertyValue="{ row }">
            <el-input
              v-model="row.propertyValue"
              placeholder="请输入属性值"
              @change="changeItem(row, 'propertyValue')"
            />
          </template>
          <template #state="{ row }">
            <el-radio-group v-model="row.state">
              <el-radio :label="0">
                无效
              </el-radio>
              <el-radio :label="1">
                有效
              </el-radio>
            </el-radio-group>
          </template>
        </Table>
        <div class="form-operation">
          <el-button
            size="small"
            plain
            class="widen-button"
            @click="editFormVisible = false"
          >
            取消
          </el-button>
          <el-button
            size="small"
            type="primary"
            :loading="loading"
            class="title widen-button"
            @click="submitForm('editForm')"
          >
            保存
          </el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Table from '@/components/Table'
import Pagination from '@/components/Pagination2'
// import LeftTree from './components/LeftTree'
// import { translateDataToTree } from '@/utils/util'
import { goodsWeightsDetail } from '@/api/goods'
import {
  goodsProductionStartOrDisableOrDelete,
  addAndEditGoodsWeights,
  getGoodsWeightsPage,
  getGoodsSpecsList,
  getGoodsProductionPage,
  getChildGoodsList,
  getGoodsMaterialsList
} from '@/api/goods'
export default {
  // 注册组件
  components: {
    Pagination,
    Table
  },
  data() {
    return {
      editFormVisible: false,
      // leftTreeData: [],
      editFormData: {
        categoryId: '',
        varietyId: '',
        goodsId: '',
        materials: ''
      },
      propertyListData: [],
      propertyOperationButton: [
        {
          bType: 'danger',
          icon: 'el-icon-remove-outline',
          handleEvent: this.deleteRow
        }
      ],
      propertyItemData: [
        {
          label: '属性名',
          prop: 'propertyName'
        },
        {
          label: '属性值',
          prop: 'propertyValue'
        },
        {
          label: '状态',
          prop: 'state',
          width: 100
        }
      ],
      title: '添加',
      itemData: [
        {
          label: '品类/品种/品名',
          prop: 'gather'
        },
        {
          label: '材质',
          prop: 'materials'
        },
        {
          label: '规格',
          prop: 'specs'
        },
        {
          label: '产地/厂家',
          prop: 'productions'
        },
        {
          label: '长度(米)',
          prop: 'length'
        },
        {
          label: '件重(吨)',
          prop: 'weights'
        }
        // {
        //   label: "状态",
        //   prop: "state",
        //   child: [
        //     { value: 1, label: "有效" },
        //     { value: "0", label: "无效" }
        //   ]
        // }
      ],
      operationButton: [
        { bType: 'primary', label: '详情', handleEvent: this.viewDetails },
        { bType: 'primary', label: '编辑', handleEvent: this.editAndAddRow },
        {
          withParameters: 2,
          bType: 'danger',
          label: '删除',
          handleEvent: this.disableEnabledRow
        }
      ],
      loading: false,
      pinzhong: '',
      rules: {
        // productions: [{ required: true, message: '请输入产地', trigger: 'blur' }],
        weights: [{ required: true, message: '请输入件重', trigger: 'blur' }],
        categoryId: [
          { required: true, message: '请选择品类', trigger: 'change' }
        ],
        varietyId: [
          { required: true, message: '请选择品种', trigger: 'change' }
        ],
        goodsId: [{ required: true, message: '请选择品名', trigger: 'change' }],
        materials: [
          { required: true, message: '请选择材质', trigger: 'change' }
        ],
        specs: [
          { required: true, message: '请选择规格', trigger: 'change' }
        ],
        productions: [
          { required: true, message: '请选择产地', trigger: 'change' }
        ],
        state: [{ required: true, message: '请选择状态', trigger: 'change' }]
      },
      listData: [],
      varietyData1: [],
      varietyData2: [],
      varietyData3: [],
      varietyData4: [],
      varietyData5: [],
      editNeedFormData: {
        varietyData1: [],
        varietyData2: [],
        varietyData3: [],
        varietyData4: [],
        varietyData5: [],
        varietyData6: [],
        varietyData7: []
      },
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      data: [],
      restaurants: [],
      total: 0,
      cascaderValue: [],
      // 划级联渲染规则
      categoryCascadeProps: {
        lazy: true,
        lazyLoad: this.categoryCascaderLazyLoad,
        value: 'code',
        label: 'goodsName'
      }
    }
  },
  activated() {
    if (this.$route.query.initData) this.getdata()
  },
  created() {
    this.getdata()
    this.getChildNode()
    // this.init()
  },
  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    // 判断材质型号是否要判断全局提示
    judgement() {
      if (!this.formInline.goodsId) this.$message.error('请先选择品名')
    },
    // 当选择框改变的时候
    categoryChange(arr) {
      this.formInline.categoryId = arr.length === 3 ? arr[0] : ''
      this.formInline.varietyId = arr.length === 3 ? arr[1] : ''
      this.formInline.goodsId = arr.length === 3 ? arr[2] : ''
      this.formInline.specs = ''
      this.formInline.materials = ''
      if (arr.length === 3) {
        this.searchMaterials()
        this.searchSpecs()
        return
      }
      this.editNeedFormData.varietyData6 = []
      this.editNeedFormData.varietyData7 = []
    },
    // 查看详情
    viewDetails({ id }) {
      this.$router.push({
        path: '/goods/commodityManagementDeatail',
        query: { id }
      })
    },
    // 输入框值改变的时候
    changeItem(row, value) {
      if (row[value].length > 20) this.$message.error('请限制在20个字符')
      this.$set(
        row,
        value,
        row[value].length > 20 ? row[value].substring(0, 20) : row[value]
      )
    },
    // 删除这一列
    deleteRow(row) {
      this.propertyListData.forEach((item, index) => {
        if (row.detailId === item.detailId) { this.propertyListData.splice(index, 1) }
      })
    },
    getFollowingData() {
      this.editFormData.materials = ''
      this.editFormData.specs = ''
      this.remoteMethod1()
      this.remoteMethod2()
    },
    // 产地的联动狂
    remoteMethod(queryString) {
      const value = queryString instanceof Object ? '' : queryString
      getGoodsProductionPage(
        { pageNum: 1, pageSize: 1000, brandProduction: value || '' },
        res => {
          this.restaurants = res.data.pageData
        }
      )
    },
    searchMaterials() {
      getGoodsMaterialsList(
        { state: 1, categoryId: this.formInline.categoryId, varietyId: this.formInline.varietyId, goodsId: this.formInline.goodsId },
        res => {
          this.editNeedFormData.varietyData6 = [...res.data]
        }
      )
    },
    searchSpecs() {
      getGoodsSpecsList(
        { state: 1, specs: '', categoryId: this.formInline.categoryId, varietyId: this.formInline.varietyId, goodsId: this.formInline.goodsId },
        res => {
          this.editNeedFormData.varietyData7 = [...res.data]
        }
      )
    },
    remoteMethod1(value) {
      getGoodsMaterialsList(
        { state: 1, materials: value || '', categoryId: this.editFormData.categoryId, varietyId: this.editFormData.varietyId, goodsId: this.editFormData.goodsId },
        res => {
          this.editNeedFormData.varietyData4 = [...res.data]
        }
      )
    },
    remoteMethod2(value) {
      getGoodsSpecsList(
        { state: 1, specs: value || '', categoryId: this.editFormData.categoryId, varietyId: this.editFormData.varietyId, goodsId: this.editFormData.goodsId },
        res => {
          this.editNeedFormData.varietyData5 = [...res.data]
        }
      )
    },
    // 修改和编辑
    editAndAddRow(row) {
      this.editFormVisible = true
      this.propertyListData = []
      if (row.id) {
        // 把下拉框的值带上
        goodsWeightsDetail(row.id, res => {
          const obj = { ...res.data }
          this.getChildNode(1, obj.categoryId, 12)
          this.getChildNode(2, obj.varietyId, 12)
          this.editFormData = { ...obj }
          if (obj.materials) this.remoteMethod1(obj.materials)
          if (obj.specs) this.remoteMethod2(obj.specs)
          if (obj.propertyList) {
            obj.propertyList.forEach(item => {
              this.propertyListData.push({
                propertyName: item.detailPropertyName,
                propertyValue: item.detailPropertyValue,
                sort: item.detailSort,
                state: item.detailState,
                id: item.detailId
              })
            })
          }
          this.title = '编辑'
          this.remoteMethod1()
          this.remoteMethod2()
        })
        return
      }
      if (this.formInline.categoryId) { this.getChildNode(1, this.formInline.categoryId, 12) }
      if (this.formInline.varietyId) { this.getChildNode(2, this.formInline.varietyId, 12) }
      this.title = '添加'
      // this.editNeedFormData.varietyData4 = []
      // this.editNeedFormData.varietyData5 = []
      this.$nextTick(() => {
        this.$refs['editForm'].resetFields()
        this.editFormData = {
          categoryId: this.formInline.categoryId || '',
          varietyId: this.formInline.varietyId || '',
          goodsId: this.formInline.goodsId || '',
          materials: this.formInline.materials || '',
          specs: this.formInline.specs || ''
        }
        this.remoteMethod1()
        this.remoteMethod2()
      })
    },
    // 点击新增或者修改的确认按钮
    submitForm(editData) {
      this.$refs[editData].validate(valid => {
        if (valid) {
          this.loading = true
          this.editNeedFormData.varietyData3.forEach(val => {
            if (val.code === this.editFormData.goodsId) { this.editFormData.goodsName = val.goodsName }
          })
          const list = []
          // 进行判断需要的属性
          const flag = this.propertyListData.every((item, index) => {
            if (
              item.propertyName &&
              item.propertyValue &&
              (item.state === 1 || item.state === 0)
            ) { list.push({ id: index, ...item, sort: index }) }
            // 三个都没有就不进行判断了
            if (!item.propertyName && !item.propertyValue && !item.state) { return true }
            return (
              item.propertyName &&
              item.propertyValue &&
              (item.state === 1 || item.state === 0)
            )
          })
          if (!flag) {
            this.loading = false
            this.$message.error('请完善其他属性')
            return
          }
          this.editFormData.propertyList = [...list]
          addAndEditGoodsWeights(
            this.editFormData,
            () => {
              this.loading = false
              this.editFormVisible = false
              this.$message.success('成功！')
              this.formInline.categoryId = this.editFormData.categoryId
              this.formInline.goodsId = this.editFormData.goodsId
              this.formInline.varietyId = this.editFormData.varietyId
              this.getdata(true)
            },
            () => {
              this.loading = false
            }
          )
        }
      })
    },
    // 获取数据
    getdata(type) {
      if (!type) {
        this.formInline.pageNum = 1
      }
      getGoodsWeightsPage(
        this.formInline,
        res => {
          this.listData = [...res.data.pageData].map(item => {
            item.gather = `${item.categoryName}/${item.varietyName}/${item.goodsName}`
            if (item.weights)item.weights = item.weights.toFixed(2)
            return { ...item }
          })
          this.total = res.data.total
        },
        () => {
          this.total = 0
          this.listData = []
        }
      )
    },
    // 禁用或者启用
    disableEnabledRow(row, state) {
      this.$confirm('此操作将改变或删除状态, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          goodsProductionStartOrDisableOrDelete({ id: row.id, state }, () => {
            this.$message.success('成功')
            this.getdata(true)
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 级联选择器懒加载
    categoryCascaderLazyLoad(node, resolve) {
      const { level, value: code = '' } = node
      const params = level === 0 ? '' : code
      getChildGoodsList(params, res => {
        const data = res.data.map(item => {
          return {
            leaf: level >= 2,
            ...item
          }
        })
        resolve([...data])
      })
    },
    // 根据父节点获取下面的子节点 edit判断是弹出的框
    getChildNode(type, pId, edit) {
      getChildGoodsList(pId || '', res => {
        const list = res.data.filter(item => item.state === 1)
        // 这里是弹出层联动执行
        if (edit) {
          switch (type) {
            case 1:
              this.editNeedFormData.varietyData2 = [...list]
              if (edit !== 12) {
                this.editFormData.goodsId = ''
                this.editFormData.varietyId = ''
                this.editNeedFormData.varietyData3 = []
              }
              break
            case 2:
              if (edit !== 12) this.editFormData.goodsId = ''
              this.editNeedFormData.varietyData3 = [...list]
              break
          }
          this.$forceUpdate()
          return
        }
        // 下面是搜索框联动执行
        switch (type) {
          case 1:
            this.varietyData2 = [...list]
            this.formInline.goodsId = ''
            this.formInline.varietyId = ''
            this.varietyData3 = []
            break
          case 2:
            this.formInline.goodsId = ''
            this.varietyData3 = [...list]
            break
          default:
            this.varietyData1 = [...list]
            this.editNeedFormData.varietyData1 = [...list]
            break
        }
      })
    },
    // 重置
    resetFormInline() {
      this.formInline = {
        pageSize: 10,
        pageNum: 1
      }
      this.editNeedFormData.varietyData6 = []
      this.editNeedFormData.varietyData7 = []
      this.cascaderValue = []
      this.getdata()
    }
  }
}
</script>

<style lang="scss" scoped>
.heavyItems {
  .search-box{
    flex: 1;
    padding: 0;
    margin: 0;
    .el-form-item{
      margin-bottom: 0;
    }
  }
  .add-button{
    height: 32px;
  }
  .contentArea {
    display: flex;
    overflow-x: hidden;
    height: 100%;
  }
  .propertyList {
    margin-bottom: 18px;
  }
  .addButton {
    float: right;
    padding-bottom: 12px;
    text-align: center;
    span {
      display: inline-block;
      height: 28px;
      line-height: 28px;
      cursor: pointer;
      width: 84px;
      color: #fff;
      background-color: #0089ff;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 500;
    }
  }
  .otherAttributes {
    font-size: 14px;
    color: #333;
    padding-bottom: 12px;
  }
  .contentArea .left {
    flex: 0 0 200px;
    padding-right: 12px;
    overflow-x: auto;
    margin-right: 20px;
  }
  .contentArea .right {
    width: calc(100% - 212px);
    height: 100%;
  }
  /deep/.el-radio__label {
    display: inline-block !important;
  }
  .form-search {
    margin-top: 20px;
  }
  .from .el-select,
  .from .el-input {
    width: 100%;
  }
  .userRole {
    width: 100%;
  }
  .buttonCombination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 42px;
    font-size: 14px;
    border: 1px solid #eee;
    border-bottom: 0;
    padding: 0 16px;
    .el-button {
      margin-top: 12px;
    }
  }
}
</style>
